import React, { useState } from 'react';
import MoonLoader from "react-spinners/MoonLoader";
import { apiWithoutAuth } from '../utils';
import AuthCode from 'react-auth-code-input';
import { Container, Button } from '@mui/material';
import { toast } from 'react-toastify';

const styles = {
  container: {
    //display: 'flex',
    //flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  heading: {
    textAlign: `center`,
    fontSize: 30,
    fontWeight: 'bold',
    margin: 30,
  },
  error: {
    fontSize: 24,
    color: '#f0506e',
    backgroundColor: '#fef4f6',
    marginBottom: 30,
    marginHorizontal: 30,
    padding: 10,
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 30,
  },
  sendButton: {
    padding: '10px 30px',
    borderColor: '#3465F5',
    borderWidth: 3,
    borderRadius: 6,
  },
  sendButtonDisabled: {
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderColor: '#3465F5',
    borderWidth: 3,
    borderRadius: 6,
    opacity: 0.5,
  },
  sendButtonIcon: {
    color: '#3465F5',
  },
  sendButtonText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#3465F5',
  },
  cancelButton: {
    padding: '0 20px',
    margin: '20px 0'
  },
  cancelButtonDisabled: {
    padding: '0 20px',
    margin: '20px 0',
    opacity: 0.5,
  },
  cancelButtonText: {
    fontSize: 20,
    textDecorationLine: 'underline',
  },
  spinner: {
    position: 'absolute',
    left: '40%',
    top: '40%',
  }
};
const ConfirmationCodeForm = ({ isTablet, closeDialog }) => {
  const [text, setText] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const submit = () => {
    console.log('submitting:' + text);
    setSubmitting(true);
    apiWithoutAuth('/auth/confirm_code', { confirmationCode: text })
      .then((res) => {
        closeDialog(res.data);
        toast.success('Your device is successfully authenticated.');
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err ? err.message : err);
      })
      .finally(() => setSubmitting(false));
  }
  return (
    <Container>
      <div style={styles.heading}>Input your confirmation code</div>
      {errorMessage &&
      <div style={styles.error}>{errorMessage}</div>
      }
      <AuthCode 
        allowedCharacters='numeric'
        onChange={setText}
        maxLength={6}
        autoFocus={true}
        containerClassName='auth-input-container'
        inputClassName={isTablet ? 'auth-input-tablet' : 'auth-input'}
      />
      <div style={styles.actionsRow}>
        <div style={styles.cancelButton} onClick={() => closeDialog()} disabled={submitting}>
          <div style={styles.cancelButtonText}>Cancel</div>
        </div>
        <div onClick={submit} disabled={submitting}>
          <div style={submitting ? styles.sendButtonDisabled : styles.sendButton}>
            <MoonLoader
              color="#666"
              loading={submitting}
              cssOverride={styles.spinner}
              size={80}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <Button variant='outlined' sx={styles.sendButtonText}>Confirm</Button>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ConfirmationCodeForm;