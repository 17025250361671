import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { IoIosKey } from "react-icons/io";
import { Container, Row, Col } from 'react-grid-system';
import { auth, signInWithCustomToken } from '../firebase';
import logo from '../assets/logo.png';
import image from '../assets/image.png';
import ConfirmationCodeForm from '../components/ConfirmationCodeForm';

const variableStyles = (isTablet) => ({
  heading: {
    fontSize: isTablet ? 34 : 28,
    textAlign: 'center',
    color: '#667E8B',
  },
  leading: {
    fontSize: isTablet ? 24 : 19,
    textAlign: 'center',
    //marginTop: 20,
    lineHeight: isTablet ? '30px' : '24px',
  },
  scanButtonText: {
    //flex: 1,
    fontSize: isTablet ? '24px' : '18px',
    fontWeight: 'bold',
    color: '#3465F5',
    marginLeft: 10,
  },
});

const styles = {
  container: {
    height: '100%'
  },
  introduction: {
    display: 'flex',
    flexDirection: 'column',
  },
  logoRow: {
    paddingTop: 50,
    paddingHorizontal: 30,
    textAlign: 'center'
  },
  logoRowTablet: {
    height: '50%',
    paddingTop: 50,
    paddingBottom: 20,
    paddingHorizontal: 30,
    textAlign: 'center',
  },
  logo: {
    maxWidth: '80%',
    margin: 'auto'
  },
  logoTablet: {
    maxWidth: '40%',
    width: '40%',
    margin: 'auto'
  },
  imageRow: {
    //flex: 4,
    height: '40%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    //flex: 1,
    alignSelf: 'stretch',
    resizeMode: 'contain',
    maxWidth: '90%',
    maxHeight: '90%',
  },
  textRow: {
    paddingHorizontal: 30,
    justifyContent: 'center',
  },
  actionsRow: {
    display: 'flex',
    // flex: 2,
    paddingHorizontal: 30,
    justifyContent: 'center',
  },
  scanButton: {
    display: 'flex',
    //flexDirection: 'row',
    alignItems: 'center',
    borderStyle: 'solid',
    borderColor: '#3465F5',
    borderWidth: 3,
    borderRadius: 6,
    background: '#ffffff',
    padding: '5px 10px',
    margin: '30px auto',
  },
  scanButtonTablet: {
    display: 'flex',
    //flexDirection: 'row',
    alignItems: 'center',
    borderStyle: 'solid',
    borderColor: '#3465F5',
    borderWidth: 4,
    borderRadius: 8,
    background: '#ffffff',
    padding: '10px 15px',
    margin: '30px auto',
  },
  scanButtonIcon: {
    fontSize: '24px',
    color: '#3465F5',
  },
  confirmationCodeDialog: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    }
  },
};

const Auth = ({ isTablet }) => {
  const [scanning, setScanning] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    if (!token) {
      return;
    }
    signInWithCustomToken(auth, token)
      .then((userCredential) => {
        console.log('signed in:', userCredential.user);
      })
      .catch((err) => alert(err.message));
  }, [token]);

  const startScanning = () => {
    setScanning(true);
    setToken('');
  }

  const closeDialog = (data) => {
    if (data) {
      //const json = JSON.parse(data);
      console.log('get token:', data);
      setToken(data.token);
    }
    setScanning(false);
  }

  return (
    <>
      <Modal
        isOpen={scanning}
        onRequestClose={closeDialog}
        style={styles.confirmationCodeDialog}>
        <ConfirmationCodeForm closeDialog={closeDialog} isTablet={isTablet}/>
      </Modal>
      <Container fluid={false} style={styles.container}>
        <Row style={{minHeight: '100%'}} align='center' direction='column'>
          <Col md={12} style={isTablet ? styles.logoRowTablet : styles.logoRow}>
            <img style={isTablet ? styles.logoTablet : styles.logo} src={logo} alt='logo'/>
          </Col>
          <Col md={8} style={styles.imageRow}>
            <img style={styles.image} src={image} alt='MeetingRule'/>
          </Col>
          <Col md={8} style={styles.textRow} ><div style={variableStyles(isTablet).heading}>Make the most of Your meeting spaces</div></Col>
          <Col md={8}  style={styles.textRow} ><div style={variableStyles(isTablet).leading}>A meeting becomes more prepared, a participant becomes more punctual, and a company becomes more productive.</div></Col>
          <Col md={8} style={styles.actionsRow}>
              <button style={isTablet ? styles.scanButtonTablet : styles.scanButton} onClick={startScanning}>
                <IoIosKey size={styles.scanButtonIcon.fontSize} color={styles.scanButtonIcon.color} />
                <span style={variableStyles(isTablet).scanButtonText}>Activate with Confirmation Code</span>
              </button>
            {/*__DEV__ && <TextInput value={token} onChangeText={(value) => setToken(value)} style={{ width: 350, height: 30, borderWidth: 1, padding: 3, marginTop: 10 }} />*/}
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Auth;
