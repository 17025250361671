import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useMedia } from "react-use";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from './firebase';
import Auth from './pages/Auth';
import Calendar from './pages/Calendar';
import './App.css';

const App = () => {
  const [user, setUser] = useState(undefined);
  const isTablet = useMedia("(min-width: 600px)");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log('user:', user);
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  Modal.setAppElement(document.getElementById('root'));

  return (
    <>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar
      />
      {user === undefined ? null : user ? <Calendar isTablet={isTablet} /> : <Auth isTablet={isTablet} />}

     
      {/* {user === undefined ? null : user ? <Calendar isTablet={isTablet} /> : <Auth isTablet={isTablet} />} */}
      </>
    );
}

export default App;
