import React, { useContext, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import CalendarContext from './CalendarContext';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
const topMargin = 10;
const timeLabelFontSize = 13;
const defaultStartHour = 8;
const defaultEndHour = 17;
const eventsLeftMargin = 15;
const eventsRightMargin = timeLabelFontSize * 4;

const styles = {
  container: {
    position: 'relative'
  },
  timeLabel: {
    color: 'lightgrey',
    fontSize: timeLabelFontSize,
    position: 'absolute',
    right: 10,
  },
  currentTimeLine: {
    height: 3,
    position: 'absolute',
    backgroundColor: '#F8D958',
    left: 5,
    right: 5,
  },
  event: {
    borderRadius: 5,
    borderWidth: 2,
    position: 'absolute',
    flex: 1,
    overflow: 'hidden',
    backgroundColor: '#FFFFFF33',
    borderColor: '#FFFFFF99',
  },
  event_needsAction: {
    backgroundColor: '#FFFFFF00',
    borderColor: '#FFFFFF66',
  },
  event_tentative: {
    backgroundColor: '#FFFFFF00',
    borderColor: '#FFFFFF66',
  },
  highlightedEvent: {
    backgroundColor: '#FFFFFF99',
  },
  eventText: {
    color: '#FFFFFFFF',
  },
  eventText_needsAction: {
    color: '#FFFFFF66',
  },
  eventText_tentative: {
    color: '#FFFFFF66',
  },
  eventSummary: {
    fontWeight: 'bold',
  },
  eventDateTime: {
  },
  eventDescription: {
  }
};

const getEventHorizontalPositions = (events) => {
  const columns = [];
  events.forEach((event, i) => {
    if (i === 0) {
      columns.push([event]);
      return;
    }
    const placed = columns.some((column) => {
      if (dayjs(event.start.dateTime).isSameOrAfter(dayjs(column[column.length - 1].end.dateTime))) {
        column.push(event);
        return true;
      }
      return false;
    });
    if (!placed) {
      columns.push([event]);
    }
  });

  const getColspan = (event, rightColumns) => {
    let colspan = 1;
    for (const column of rightColumns) {
      for (const rightEvent of column) {
        if (dayjs(event.start.dateTime).isBetween(dayjs(rightEvent.start.dateTime), dayjs(rightEvent.end.dateTime)) || dayjs(event.end.dateTime).isBetween(dayjs(rightEvent.start.dateTime), dayjs(rightEvent.end.dateTime))) {
          return colspan;
        }
      }
      colspan++;
    }
    return colspan;
  }

  const horizontalPositions = {};
  columns.forEach((column, i) => {
    column.forEach((event) => {
      horizontalPositions[event.id] = {
        summary: event.summary,
        columnNum: i,
        colspan: getColspan(event, columns.slice(i + 1)),
        columnCount: columns.length,
      };
    });
  });
  return horizontalPositions;
}

const DailyCalendar = ({ height }) => {
  const { calendarState, settings, device, events, selectedEvent, setSelectedEvent, detailsVisible, now } = useContext(CalendarContext);
  const [width, setWidth] = useState(undefined);
  const myElm = useRef(null); 
  const { timezone } = settings;

  const today = dayjs().tz(timezone).startOf('day');
  const tomorrow = dayjs().tz(timezone).startOf('day').add(1, 'day');
  const isToday = (dateTime) => dayjs(dateTime).tz(timezone).isSame(today, 'day');

  const todaysEvents = events.filter((event) => isToday(event.start.dateTime) || isToday(event.end.dateTime));
  const highlightedEvent = selectedEvent || calendarState.ongoingEvent || calendarState.upcomingEvent || {};

  let startHour = defaultStartHour;
  let endHour = defaultEndHour;
  todaysEvents.forEach((event) => {
    const eventStartHour = isToday(event.start.dateTime) ? dayjs(event.start.dateTime).tz(timezone).hour() : 0;
    const eventEndHour = isToday(event.end.dateTime) ? Math.ceil(dayjs(event.end.dateTime).tz(timezone).hour() + dayjs(event.end.dateTime).tz(timezone).minute() / 60) : 24;
    startHour = eventStartHour < startHour ? eventStartHour : startHour;
    endHour = endHour < eventEndHour ? eventEndHour : endHour;
  });

  const hourHeight = (height - timeLabelFontSize - topMargin * 2) / (endHour - startHour);

  const getTimeOffsetTop = (dateTime) => {
    //console.log('getTimeOffsetTop:' + dateTime + ' timezone:' + timezone + ' startHour:' + dayjs().tz(timezone).hour(startHour) + ' endHour:' +  dayjs().tz(timezone).hour(endHour));
    if (!dayjs(dateTime).isBetween(dayjs().tz(timezone).hour(startHour), dayjs().tz(timezone).hour(endHour))) {
      return -1;
    }
    return hourHeight * (dayjs(dateTime).tz(timezone).hour() - startHour) + topMargin + (timeLabelFontSize / 2) + hourHeight * dayjs(dateTime).tz(timezone).minute() / 60;
  }

  const getEventHeight = (startDateTime, endDateTime) =>
  dayjs(endDateTime).diff(startDateTime, 'hours', true) * hourHeight;

  const currentTimeLineTop = getTimeOffsetTop(now);
  const eventHorizontalPositions = getEventHorizontalPositions(todaysEvents);

  useEffect(() => {
    setWidth(myElm.current.getBoundingClientRect().width);
  }, []);

  return (
    <div ref={myElm} id='dailyCalendar' style={Object.assign({ height: height }, styles.container)} onResize={(e) => setWidth(myElm.current.getBoundingClientRect().width)}>
      {currentTimeLineTop > -1 &&
      <div style={Object.assign({ top: currentTimeLineTop }, styles.currentTimeLine)} />
      }
      {Array.from(Array(endHour - startHour + 1), (_, i) => startHour + i).map((i, index) =>
      <div key={i} style={Object.assign({ top: hourHeight * index + topMargin }, styles.timeLabel)}>{i}:00</div>
      )}
      {width && todaysEvents.map((event) =>
      <div key={event.id} onClick={() => setSelectedEvent(event)}>
        <div style={{...styles.event, ...styles[`event_${event.responseStatus}`], /*highlightedEvent.id === event.id ? styles.highlightedEvent : null,*/ ...{
          top: getTimeOffsetTop(isToday(event.start.dateTime) ? event.start.dateTime : today),
          height: getEventHeight(isToday(event.start.dateTime) ? event.start.dateTime : today, isToday(event.end.dateTime) ? event.end.dateTime : tomorrow),
          left: eventsLeftMargin + (eventHorizontalPositions[event.id].columnNum / eventHorizontalPositions[event.id].columnCount) * (width - eventsLeftMargin - eventsRightMargin),
          right: eventsRightMargin + ((eventHorizontalPositions[event.id].columnCount - eventHorizontalPositions[event.id].columnNum - eventHorizontalPositions[event.id].colspan) / eventHorizontalPositions[event.id].columnCount) * (width - eventsLeftMargin - eventsRightMargin),
          paddingVertical: hourHeight * 0.075,
          paddingHorizontal: hourHeight * 0.2,
        }}}>
          {event.visibility !== 'private' &&
          <>
          <div style={{...styles.eventSummary, ...styles.eventText, ...styles[`eventText_${event.responseStatus}`], ...{ fontSize: timeLabelFontSize }}}>{detailsVisible && device.summaryVisible ? event.summary : 'Reserved'}</div>
          <div style={{...styles.eventDateTime, ...styles.eventText, ...styles[`eventText_${event.responseStatus}`], ...{ fontSize: timeLabelFontSize * 0.8, marginTop: timeLabelFontSize * 0.1}}}>{dayjs(event.start.dateTime).tz(settings.timezone).format('H:mm')} - {dayjs(event.end.dateTime).tz(settings.timezone).format('H:mm')}</div>
          {detailsVisible && device.descriptionVisible &&
          <span style={{...styles.eventDescription, ...styles.eventText, ...styles[`eventText_${event.responseStatus}`], ...{ fontSize: timeLabelFontSize * 0.8, marginTop: timeLabelFontSize * 0.4 }}}>{event.description}</span>
          }
          </>
          }
        </div>
      </div>
      )}
    </div>
  );
}
export default DailyCalendar;
